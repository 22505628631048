import React, { useMemo } from 'react'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => {
  // Hero
  const heroPreset1 = {
    fontWeight: 400,
    fontFamily: 'PangramSansRounded, Helvetica, Arial, sans-serif',
    marginBottom: theme.typography.pxToRem(16),
    fontSize: theme.typography.pxToRem(40),
    lineHeight: theme.typography.pxToRem(63),
    letterSpacing: theme.typography.pxToRem(-.8),
  };

  // Heading H1
  const headingH1 = {
    fontWeight: 500,
    fontFamily: 'PangramSansRounded, Helvetica, Arial, sans-serif',
    marginBottom: 40,
    fontSize: theme.typography.pxToRem(38),
    lineHeight: theme.typography.pxToRem(63),
    letterSpacing: theme.typography.pxToRem(-.8),
  };

  // Heading H2
  const headingH2 = {
    fontWeight: 500,
    fontFamily: 'PangramSansRounded, Helvetica, Arial, sans-serif',
    marginBottom: 24,
    fontSize: theme.typography.pxToRem(30),
    lineHeight: theme.typography.pxToRem(50),
    letterSpacing: theme.typography.pxToRem(-.3),
  };
  // Heading H3
  const headingH3 = {
    fontWeight: 500,
    fontFamily: 'PangramSansRounded, Helvetica, Arial, sans-serif',
    marginBottom: 16,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(30),
    letterSpacing: theme.typography.pxToRem(-.15),
  };
  // Heading H4
  const headingH4 = {
    fontWeight: 500,
    fontFamily: 'PangramSansRounded, Helvetica, Arial, sans-serif',
    marginBottom: 16,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(28),
    letterSpacing: theme.typography.pxToRem(-.05),
  };
  // Heading H5
  const headingH5 = {
    fontWeight: 500,
    fontFamily: 'PangramSansRounded, Helvetica, Arial, sans-serif',
    marginBottom: 16,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(28),
    letterSpacing: theme.typography.pxToRem(-.05),
  };

  // Paragraph/xLarge
  const paragraphXl = {
    fontWeight: 400,
    marginBottom: 20,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(38),
    letterSpacing: 0,
  };
  // Paragraph/Large
  const paragraphLg = {
    fontWeight: 400,
    marginBottom: 20,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(27),
    letterSpacing: 0,
  };
  // Paragraph/Medium
  const paragraphMd = {
    fontWeight: 400,
    marginBottom: 16,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(24),
    letterSpacing: 0,
  };
  // Paragraph/Small
  const paragraphSm = {
    fontWeight: 400,
    marginBottom: 16,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(21),
    letterSpacing: 0,
  };

  // Label/Large
  const labelLg = {
    fontWeight: 400,
    marginBottom: 12,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(16),
    letterSpacing: theme.typography.pxToRem(.2),
  };
  // Label/Medium
  const labelMd = {
    fontWeight: 400,
    marginBottom: 12,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(16),
    letterSpacing: theme.typography.pxToRem(.2),
  };
  // Label/Small
  const labelSm = {
    fontWeight: 400,
    marginBottom: 12,
    fontSize: theme.typography.pxToRem(11),
    lineHeight: theme.typography.pxToRem(16),
    letterSpacing: theme.typography.pxToRem(.2),
  };

  return {
    root: {
      margin: 0,
    },

    link: {
      textDecoration: 'underline',
    },

    disableMargin: {
      marginBottom: 0,
    },

    titlePreset4: {
      ...headingH1,
      [theme.breakpoints.only('xs')]: {
        ...headingH2
      }
    },
    heroPreset1: {
      ...heroPreset1,
      [theme.breakpoints.only('xs')]: {
        ...headingH2
      }
    },
    headingPreset1: {
      ...headingH2,
      [theme.breakpoints.only('xs')]: {
        ...headingH3
      }
    },
    headingPreset2: {
      ...headingH3,
      [theme.breakpoints.only('xs')]: {
        ...headingH4
      }
    },
    headingPreset3: {
      ...headingH4,
      [theme.breakpoints.only('xs')]: {
        ...headingH5
      }
    },
    headingPreset4: headingH5,
    paragraphPreset1: {
      ...paragraphXl,
      [theme.breakpoints.only('xs')]: {
        ...paragraphLg
      }
    },
    paragraphPreset2: {
      ...paragraphLg,
      [theme.breakpoints.only('xs')]: {
        ...paragraphMd
      }
    },
    paragraphPreset3: {
      ...paragraphMd,
      [theme.breakpoints.only('xs')]: {
        ...paragraphSm
      }
    },
    paragraphPreset4: paragraphSm,
    labelPreset1: {
      ...labelLg,
      [theme.breakpoints.only('xs')]: {
        ...labelMd
      }
    },
    labelPreset2: {
      ...labelMd,
      [theme.breakpoints.only('xs')]: {
        ...labelSm
      }
    },
    labelPreset3: labelSm,
  }
}, { name: 'ThriveTypography' });

const componentMap = {
  titlePreset4: 'h1',
  heroPreset1: 'h1',
  headingPreset1: 'h2',
  headingPreset2: 'h3',
  headingPreset3: 'h4',
  headingPreset4: 'h5',
  paragraphPreset1: 'p',
  paragraphPreset2: 'p',
  paragraphPreset3: 'p',
  paragraphPreset4: 'p',
  labelPreset1: 'label',
  labelPreset2: 'label',
  labelPreset3: 'label',
}


const Typography = React.forwardRef((props, ref) => {
  const {
    type = 'paragraph',
    preset = 3,

    disabled,
    link,
    coolBean,

    disableMargin = false,
    component,
    className,

    children,
    ...otherProps
  } = props;

  const classStyles = useStyles({ disableMargin });

  const internalPreset  = useMemo(() => `${type}Preset${preset}`, [type, preset]);
  const htmlElement = useMemo(() => component ?? componentMap[internalPreset] ?? 'p', [internalPreset, component]);

  const classList = clsx(
    classStyles.root,
    classStyles[internalPreset],

    disabled === true ? classStyles.disabled : {},
    link === true ? classStyles.link : {},

    disableMargin && classStyles.disableMargin,

    className ?? ''
  );

  return React.createElement(
    htmlElement,
    {
      ref,
      className: classList,
      ...otherProps,
      children: (
        <>
          {children}
        </>
      )
    }
  )
})

export default Typography
