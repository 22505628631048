import React from 'react';
import { Box, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import logo from '../images/logo_navy.svg';

import Facebook from '@material-ui/icons/Facebook';
import Twitter from '@material-ui/icons/Twitter';
import LinkedIn from '@material-ui/icons/LinkedIn';

import Typography from './Typography';

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    display: 'flex',
    flexDirection:'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'center',
  },
  section: {
    marginBottom: theme.spacing(6)
  },
  link: {
    color: '#040068',
    textDecoration: 'underline',
    fontWeight: 800,
    '&:hover': {
      color: '#B670FF',
      transition: 'all 0.4s ease',
    },
    '& > &': {
      marginLeft: theme.spacing(2),
    }
  },
  links: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '300px',
    marginBottom: theme.spacing(2),
  },
  socials: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '150px',
  },
  socialLinks: {
    color: "#040068",
    '&:hover': {
      color: '#B670FF',
      transition: 'all 0.3s ease',
    },
  },
}));

const Footer = () => {
  const classes = useStyles();
  const copyRightYear = new Date().getFullYear();

  return (
    <Box className={classes.footerContainer}>
      <figure className={classes.section}>
        <img src={logo} width="206" alt="Innowell logo" />
      </figure>
      <Typography className={classes.section}>
        We recognise and pay respect to Aboriginal and Torres Strait Islander Peoples, their ancestors, the Elders past, present and future from the different First Nations across Australia. We acknowledge the importance of connection to land, culture, spirituality, ancestry, family and community for the wellbeing of all Aboriginal and Torres Strait Islander children and their families.
      </Typography>
      <Box className={classes.section}>
        <Typography>&copy; Copyright Innowell {copyRightYear}</Typography>
        <Box className={classes.links}>
          <a className={classes.link} href="/privacy-policy">Privacy Policy</a> <a className={classes.link} href="/website-terms">Terms of Use</a>
        </Box>
        <Typography>
          We are not a crisis service.
        </Typography>
      </Box>
      <Box className={classes.socials}>
        <Link href="https://www.facebook.com/innowellorg/" className={classes.socialLinks} >
          <Facebook />
        </Link>
        <Link href="https://twitter.com/innowellorg" className={classes.socialLinks} >
          <Twitter />
        </Link>
        <Link href="https://www.linkedin.com/company/innowell-org/" className={classes.socialLinks} >
          <LinkedIn />
        </Link>
      </Box>
    </Box>
  )
};

export default Footer;
