import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import Typography from './Typography';

const Section = (props) => {
  const {
    variant='regular',
    theme='secondary',
    wide = false,
    sparkly = false,
    title,
    children
  } = props;

  const themeStyles = {
    primary: {
      backgroundColor: '#FFFBFA'
    },
    secondary: {
      backgroundColor: '#FFF'
    },
    mint: {
      backgroundColor: '#F2FBFC'
    }
  }

  const variantStyles = {
    narrow: {
      padding: '4px 0',
    },
    wide: {
      padding: '124px 0',
    },
    regular: {
      padding: '54px 0',
    },
    condensed: {
      padding: '54px 112px'
    }
  }

  const sparkleStyles = (sparkly) ? {background: 'url(/images/sparkles.png) left 10px bottom -230px no-repeat'} : false;

  return (
    <Box
      component='section'
      sx={{
        ...variantStyles[variant],
        ...themeStyles[theme],
        ...sparkleStyles,
      }}
    >
      {
      (title) && 
        <Box sx={{
          textAlign:'center'
        }}>
          <Typography type='title' preset={4}>
              {title}
          </Typography>
        </Box>
      }
      <Container
        maxWidth={(wide) ? 'lg' : 'md'}
      >
        {children}
      </Container>
    </Box>
  )
}

export default Section